.Welcome {
  margin-top: 5rem;
  text-align: center;
}

.Welcome-header {
  margin-bottom: 3rem;
}

.QualifyForm {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.QualifyForm-card {
  background-color: var(--eclipse-black);
  color: var(--milky-white);
  padding: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 0px 45px color(var(--eclipse-black) alpha(40%));
  width: 100%;
}

.QualifyForm-button {
  margin-top: 2rem;
  width: 11rem;
}

.QualifyForm-radios {
  margin-bottom: 2rem;
}

.QualifyForm-radios:last-child {
  margin-bottom: 0;
}
