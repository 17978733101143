/** @define Button */

%Button--base {
  width: 100%;
  padding: 1rem;

  text-transform: uppercase;
  text-decoration: none;

  font-size: 0.875rem;
  line-height: 1.5rem;

  font-weight: 500;

  display: inline-block;
  text-align: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: var(--transition-fn-default);

  background-color: transparent;

  inherit: %component-clickable;
}

%Button--inline {
  display: inline-block;
}

%Button--primary {
  border-radius: 0.375rem;
  border: 1px solid var(--eclipse-black);

  color: var(--brand-text);
}

%Button--primary:hover {
  background: var(--brand-background-inverse);
  color: var(--brand-text-inverse);
}

%Button--inverse {
  color: var(--brand-text-inverse);
  border-color: var(--brand-text-inverse);
}

%Button--inverse:hover {
  /* (matthew): until we learn how to generalize this, leave it black */
  color: var(--brand-text);
  background-color: var(--brand-text-inverse);
}

%Button--primaryFilled {
  background: var(--brand-background-inverse);
  color: var(--brand-text-inverse);
}

%Button--secondary {
  font-weight: normal;
  color: var(--brand-text);
  border: none;
  border-radius: 0;
}

%Button--secondary:hover {
  text-decoration: underline;
  background: none;
  color: inherit;
}

%Button--confirm {
  color: var(--brand-confirm);
  border-color: var(--brand-confirm);
}

%Button--confirm:hover {
  color: var(--brand-text-inverse);
  background-color: var(--brand-confirm);
  border-color: color(var(--brand-confirm) alpha(50%));
}

%Button--reject {
  color: var(--brand-reject);
  border-color: var(--brand-reject);
}

%Button--reject:hover {
  color: var(--brand-text-inverse);
  background-color: var(--brand-reject);
  border-color: color(var(--brand-reject) alpha(50%));
}

.Button {
  inherit: %Button--base, %Button--primary;
}

.Button--inline {
  inherit: %Button--inline;
  line-height: inherit;
  padding-top: 0;
  padding-bottom: 0;
}

.Button--secondary {
  inherit: %Button--secondary;
}

.Button--confirm {
  inherit: %Button--confirm;
}

.Button--reject {
  inherit: %Button--reject;
}

.Button--inverse {
  inherit: %Button--inverse;
}

.Button--primaryFilled {
  inherit: %Button--primaryFilled;
}

.Button--small {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.Button.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}
