/** @define TextField */

:root {
  --TextField-activeColor: var(--brand-text);
  --TextField-inactiveColor: color(var(--brand-text) alpha(30%));
  --TextField-errorColor: var(--sunset-red);

  --TextField-inverseActiveColor: var(--brand-text-inverse);
  --TextField-inverseInactiveColor: color(var(--brand-text-inverse) alpha(30%));
}

.TextField {
  padding: 0.5rem 0.5rem 0.5rem 0;
  width: 100%;

  outline: 0;

  border-radius: 0;

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--TextField-inactiveColor);

  transition: border 200ms ease-in-out;

  background-color: transparent;
  color: var(--TextField-activeColor);

  position: relative;
  z-index: var(--z-index-2--textFieldInput);
}

.TextField-label {
  position: absolute;

  left: 0;
  top: 0.5rem;

  line-height: 1.5rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  z-index: var(--z-index-1--textFieldLabel);

  color: var(--TextField-inactiveColor);

  transition: top 150ms ease-in-out, color 200ms ease-in-out, font-size 200ms ease-in-out;
}

.TextField-helper {
  inherit: %type-sm;

  padding: 0.5rem 0.5rem 0 0;

  line-height: 1.5rem;

  transition: color 200ms ease-in-out;
}

.TextField-group.is-error .TextField-helper {
  color: var(--TextField-errorColor);
}

/*
  Eventually, we should consider naming the top level component
  `TextField` and calling the input `TextField-input` instead of
  having a hierarchy of `TextField-group` > `TextField`
 */
.TextField--hidesLabel.is-filled + .TextField-label {
  opacity: 0;
}

.TextField:active,
.TextField:focus,
.TextField:hover {
  border-bottom-color: var(--TextField-activeColor);
}

.TextField-group.is-error .TextField {
  border-bottom-color: var(--TextField-errorColor);
}

.TextField:active,
.TextField:focus {
  inherit: .TextField.is-filled;
}

.TextField:active:not(.TextField--hidesLabel) + .TextField-label,
.TextField:focus:not(.TextField--hidesLabel) + .TextField-label,
.TextField.is-filled:not(.TextField--hidesLabel) + .TextField-label {
  inherit: %type-sm;
  top: -1rem;
}

.TextField:active:not(.TextField--hidesLabel) + .TextField-label,
.TextField:focus:not(.TextField--hidesLabel) + .TextField-label {
  color: var(--TextField-activeColor);
}

.TextField-group {
  position: relative;
  z-index: var(--z-index-0--textFieldGroup);
}

.TextField--inverse {
  border-bottom-color: var(--TextField-inverseInactiveColor);
  color: var(--TextField-inverseActiveColor);
}

/*
  .TextField and .TextField-label are siblings, but shouldn't be.
*/
.TextField--inverse + .TextField-label {
  color: var(--TextField-inverseInactiveColor);
}

.TextField--inverse:active,
.TextField--inverse:focus,
.TextField--inverse:hover {
  border-bottom-color: var(--TextField-inverseActiveColor);
}

.TextField--inverse:active:not(.TextField--hidesLabel) + .TextField-label,
.TextField--inverse:focus:not(.TextField--hidesLabel) + .TextField-label {
  color: var(--TextField-inverseActiveColor);
}
