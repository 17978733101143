/** @define Modal */

.Modal {
  color: var(--brand-text);
  background: var(--brand-background);

  box-shadow: 0 1px 45px 1px color(var(--eclipse-black) alpha(15%));
  border-radius: 0.625rem;

  overflow: hidden;
  position: relative;

  flex-basis: auto;
  flex-shrink: 1;
  min-width: 40vw;
  width: 70vw;
  max-width: 50rem;
}

.Modal--error .Modal-content {
  color: var(--brand-text-inverse);
  background-color: var(--sunset-red);
}

.Modal-background {
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  transition: background-color 100ms ease;

  background-color: none;
  perspective: 1000px;
}

.Modal-background.is-visible {
  background-color: color(var(--eclipse-black) alpha(20%));
}

.Modal-background--absolute {
  position: absolute;
  overflow: visible;
}

%Modal-gutters {
  padding: 2.5rem;
}

.Modal-content {
  inherit: %Modal-gutters;
}

.Modal-heading {
  inherit: %type-md;
  margin-bottom: 1rem;
}

.Modal-subheading {
  inherit: %type-caption;
  color: var(--warm-grey-5);
  margin-bottom: 1rem;
}

.Modal-body {
  margin-bottom: 1rem;
}

.Modal-actions {
  inherit: %Modal-gutters, %type-bs;
  background-color: var(--washed-clay);

  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Modal-control {
  inherit: %component-clickable;

  display: inline-block;

  text-decoration: none;
  text-transform: uppercase;

  color: var(--brand-text);

  margin-right: 2.5rem;
}

.Modal-loaderContainer {
  align-self: flex-start;
}

.Modal-control--primary {
  inherit: %type-weight-500;
}

.Modal-control--confirm {
  color: var(--brand-confirm);
  inherit: %type-weight-500;
}

.Modal-control--reject {
  color: var(--brand-reject);
  inherit: %type-weight-500;
}

.Modal-control--rightAligned {
  margin-left: auto;
  margin-right: 0;
}

.Modal.is-shaking {
  animation: Modal-shake 500ms ease-in-out;
}

@keyframes Modal-shake {
  0% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-6px) rotateY(-5deg);
  }
  37.5% {
    transform: translateX(5px) rotateY(4deg);
  }
  62.5% {
    transform: translateX(-3px) rotateY(-2deg);
  }
  87.5% {
    transform: translateX(2px) rotateY(1deg);
  }
  100% {
    transform: translateX(0);
  }
}

/* postcss-bem-linter: ignore */
.ReactModal__Overlay {
  /* react-modal controls the rendering of this div,
  but we need to scale its z-index to our scale */
  z-index: var(--z-index-10--modalOverlay);
}
