/** @define RadioButton */
:root {
  /**
   * This was previously 1.33rem... (4rem / 3) but Chrome
   * renders that size at a 16px base poorly (not perfectly rounded)
   * so we raise the size slighty here to 1.35rem
   */
  --RadioButton-size: 1.35rem;
}

.RadioButton {
  height: var(--RadioButton-size);
  width: var(--RadioButton-size);
}

.RadioButton-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.RadioButton-button {
  inherit: %component-clickable;
  border-radius: 50%;
  position: relative;
  height: var(--RadioButton-size);
  width: var(--RadioButton-size);
  line-height: var(--RadioButton-size);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1px var(--warm-grey-7);
}

.RadioButton-input:disabled + .RadioButton-button {
  box-shadow: inset 0 0 0 1px var(--warm-grey-9);
  pointer-events: none;
}

.RadioButton-input:checked + .RadioButton-button {
  background-color: var(--royal-blue);
  box-shadow: none;
}

.RadioButton-button > .RadioButton-svg {
  transform-origin: 50% 75%;
  opacity: 0;
  stroke-dasharray: 15;
  stroke-dashoffset: -14;

  transition: opacity var(--transition-fn-default),
    stroke-dashoffset var(--transition-fn-default);
}

.RadioButton-input:checked + .RadioButton-button > .RadioButton-svg {
  opacity: 1;
  stroke-dashoffset: 0;
}
