/** @define PopoverMenu */

.PopoverMenu-heading {
  inherit: %type-md;
  padding: var(--Popover-padding);
}

.PopoverMenu-action {

  padding: var(--Popover-padding);
  color: var(--warm-grey-2);

  inherit: %component-clickable;

  display: flex;
  width: 100%;

  align-items: center;
  font-size: 13.5px;

  transition: background-color var(--transition-fn-default);
}

.PopoverMenu-action--disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.PopoverMenu-actionContainer:hover {
  background-color: var(--warm-grey-9);
}

.PopoverMenu-divider {
  border: none;
  border-bottom: 1px solid var(--warm-grey-9);
  margin: 0.5rem 0;
}

.PopoverMenu-icon {
  /* consider moving this to icon styling sizes */
  font-size: 1.75rem;
  line-height: 1em;
  font-weight: 300;

  margin-right: 1rem;
  color: var(--warm-grey-7);
}
