.ClaraLogo.is-hidden {
  padding-bottom: 1em;
}

.ClaraLogo-image {
  background-image: url(/static/img/logo.svg);
  width: 3em;
  height: 3em;
  background-size: contain;
  margin: auto;
}

.no-svg .ClaraLogo-image {
  background-image: url(/static/img/logo.png);
}

.ClaraLogo--white .ClaraLogo-image {
  background-image: url(/static/img/logo-white.svg);
}

.no-svg .ClaraLogo--white .ClaraLogo-image {
  background-image: url(/static/img/logo-white.png);
}

.ClaraLogo-subtext {
  inherit: %type-md;
  color: var(--milky-white);
  padding-top: .8em;
}
