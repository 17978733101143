/** @define Checkbox */
:root {
  --Checkbox-size: var(--RadioButton-size);
}

.Checkbox {
  height: var(--Checkbox-size);
  width: var(--Checkbox-size);
}

.Checkbox-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Checkbox-button {
  inherit: %component-clickable;
  border-radius: 50%;
  position: relative;
  height: var(--Checkbox-size);
  width: var(--Checkbox-size);
  line-height: var(--Checkbox-size);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1px var(--warm-grey-7);
}

.Checkbox-input:disabled + .Checkbox-button {
  box-shadow: inset 0 0 0 1px var(--warm-grey-9);
  pointer-events: none;
}

.Checkbox-input:checked + .Checkbox-button {
  background-color: var(--royal-blue);
  box-shadow: none;
}

.Checkbox-button > .Checkbox-svg {
  transform-origin: 50% 75%;
  opacity: 0;
  stroke-dasharray: 15;
  stroke-dashoffset: -14;

  transition: opacity var(--transition-fn-default),
    stroke-dashoffset var(--transition-fn-default);
}

.Checkbox-input:checked + .Checkbox-button > .Checkbox-svg {
  opacity: 1;
  stroke-dashoffset: 0;
}
