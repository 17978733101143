/** @define PopoverActionBar */

.PopoverActionBar {
  inherit: %type-bs;
  background-color: var(--washed-clay);

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.PopoverActionBar-action {
  inherit: %component-clickable;

  display: inline-block;
  margin-right: 2rem;

  text-decoration: none;
  text-transform: uppercase;
}

.PopoverActionBar-action--primary {
  inherit: %type-weight-500;
}

.PopoverActionBar-action--rightAligned {
  margin-left: auto;
  margin-right: 0;
}

.PopoverActionBar-action--confirm {
  inherit: %type-weight-500;
  color: var(--brand-confirm);
}

.PopoverActionBar-action--reject {
  inherit: %type-weight-500;
  color: var(--brand-reject);
}

.PopoverActionBar-action--disabled {
  pointer-events: none;
  opacity: 0.4;
}
