/** @define TabPanel */

:root {
  --TabPanel--unselectedColor: var(--warm-grey-5);
  --TabPanel--activeColor: var(--eclipse-black);
}

.TabPanel {
  display: flex;
  border-bottom: 1px solid var(--warm-grey-9);
}

.TabPanel-tab {
  padding: 1rem 2rem;
  color: var(--TabPanel--unselectedColor);

  inherit: %component-clickable;
  transition: color var(--transition-fn-default), border-bottom-color var(--transition-fn-default);
}

.TabPanel-tab:hover,
.TabPanel-tab:active {
  color: var(--TabPanel--activeColor);
}

.TabPanel-tab.is-selected {
  inherit: %type-weight-500;

  /* compensate for selected border */
  margin-bottom: -1px;
  border-bottom: 2px solid var(--TabPanel--activeColor);

  color: var(--brand-text);
}
