:root {
  -webkit-font-smoothing: antialiased;
  --InfoCardRow-height: 24rem;
}

.InfoCardRow {
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
}

.InfoCard-description {
  inherit: %type-sm;

  position: absolute;
  bottom: -20px;
  color: var(--eclipse-black);
}

.InfoCardRow-inner {
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  width: 1848px;
}

.InfoCardRow-top {
  position: relative;
  left: 50vw;
}

.InfoCardRow-bottom {
  float: right;
  right: 50vw;
  position: relative;
}

.InfoCard {
  inherit: %type-md;
  border-radius: .375rem;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 18rem;
  padding: 20px;
  vertical-align: top;
  text-align: left;
  white-space: normal;
  color: var(--milky-white);;
  min-height: 10em;
  max-height: 25em;
  height: 32vh;
  box-shadow: 0px 2px 8px color(var(--eclipse-black) alpha(20%));
}

.InfoCard--green {
  background-color: var(--aurora-green);
}

.InfoCard--blue {
  background-color: var(--sky-blue);
}

.InfoCard--violet {
  background-color: var(--twilight-violet);
}

.InfoCard--yellow {
  background-color: var(--california-yellow);
}

.InfoCard--pink {
  background-color: var(--dusky-pink);
}

.InfoCard--red {
  background-color: var(--sunset-red);
}

.u-moveLeft {
  animation: move-left 60s linear 0s alternate infinite;
}

.u-moveRight {
  animation: move-right 60s linear 0s alternate infinite;
}

@keyframes move-left {
  from { transform: translateX(0) }
  to { transform: translateX(-1900px) }
}

@keyframes move-right {
  from { transform: translateX(0) }
  to { transform: translateX(1900px) }
}
