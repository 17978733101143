/** @define Loader */

:root {
  --Loader-duration: 1s;
  --Loader-indicator-width: 0.5rem;
}

.Loader {
  display: inline-block;
  vertical-align: baseline;
}

.Loader--inverse > .Loader-indicator {
  background-color: var(--milky-white);
}

.Loader-indicator {
  background-color: var(--warm-grey-1);
  width: var(--Loader-indicator-width);
  height: var(--Loader-indicator-width);
  margin: 0 calc(var(--Loader-indicator-width) / 2);
  border-radius: 100%;
  display: inline-block;
  transition: opacity 0.4s;
  animation: Loader-indicator var(--Loader-duration) infinite ease-in-out;
}

.Loader-indicator:nth-child(2) {
  animation-delay: calc(0.1s + -1 * var(--Loader-duration));
}

.Loader-indicator:nth-child(3) {
  animation-delay: calc(0.2s + -1 * var(--Loader-duration));
}

@keyframes Loader-indicator {
  0%,
  60%,
  100% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
}
