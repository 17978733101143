/** @define Popover */

:root {
  --Popover-padding: 0.5rem 1.5rem;
}

.Popover {
  z-index: var(--z-index-8--popover);
  position: absolute;
}

.Popover-body {
  border-radius: 0.625rem;
  box-shadow: var(--box-shadow-5);
  background-color: var(--brand-background);
  min-width: 16rem;
  overflow: hidden;
}

.Popover-background {
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.Popover-content {
  padding: 1rem 0;
}

.Popover-tip {
  fill: var(--brand-background);
}

.Popover.is-gray .Popover-tip {
  fill: var(--warm-grey-10);
}

.Popover.is-shaking {
  animation: Popover-shake 500ms ease-in-out;
}

@keyframes Popover-shake {
  0% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-6px) rotateY(-5deg);
  }
  37.5% {
    transform: translateX(5px) rotateY(4deg);
  }
  62.5% {
    transform: translateX(-3px) rotateY(-2deg);
  }
  87.5% {
    transform: translateX(2px) rotateY(1deg);
  }
  100% {
    transform: translateX(0);
  }
}

/** @define PopoverCallToAction */
.PopoverCallToAction {
  text-transform: uppercase;
  inherit: %type-weight-500, %type-bs;
  display: flex;
  justify-content: center;
  color: var(--warm-grey-3);
  width: 100%;
  padding: 1rem;
  background-color: var(--warm-grey-10);
}

.PopoverCallToAction:hover {
  cursor: pointer;
  inherit: %component-clickable;
}

/** @define PopoverPaddedContent */
.PopoverPaddedContent {
  padding: 1rem calc(4rem / 3);
}
