.Sentience {
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  z-index: -1000;
}
.Sentience.is-transitioned {
  transition: opacity 1s ease-in-out;
}

.Sentience--fallback {
  /* background-image: url('/static/img/sentience.png'); */
  background-repeat: no-repeat;
  background-size: 120% 50%;
  background-position: 0 100%;

  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;
}

.Sentience.is-visible {
  opacity: 1;
}
