/*
 * Above the fold
 */

@import "../components/sentience.css";
@import "../../../node_modules/slick-carousel/slick/slick.css";
@import "../../../node_modules/slick-carousel/slick/slick-theme.css";

.Cursor {
  font-weight: 400;
  animation: cursor-blink 1000ms linear infinite;
  visibility: hidden;
}

.Cursor.is-visible {
  visibility: visible;
}

@keyframes cursor-blink {
  0%, 100% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
  }
  50% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
}

.AboveTheFold {
  min-height: 100vh;
  position: relative;
  border-bottom: 1px solid var(--milky-white);
  transition: all 500ms ease-in-out;
}

.CallToAction {
  height: 100%;
}

.CallToAction > .row {
  height: 100%;
}

.CallToAction-hero {
  padding-top: 8rem;
  padding-bottom: 6rem;
}

@media screen and (min-height: 32em) {
  .CallToAction-hero {
    padding-top: 30vh;
  }
}

@media screen and (min-height: 52em) {
  .CallToAction-hero {
    padding-top: 34vh;
  }
}

.CallToAction-primary {
  font-weight: 500;
  font-size: 3.75rem;
  line-height: 4.5rem;
}

.CallToAction-secondary {
  font-weight: 400;
}

.CallToAction-primary--thin {
  font-weight: 400;
}

.CallToAction-primary--thin .CallToAction-primaryHighlight {
  font-weight: 500;
}

.CallToAction-tertiary {
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  color: color(var(--brand-text) alpha(70%));
}

@media (--xs-viewport) {
  .CallToAction-hero {
    padding-top: 24vh;
    padding-bottom: 0;
  }

  .CallToAction-primary {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .CallToAction-tertiary {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: 1rem;
  }

  .SignupFormContainer {
    margin-top: 0;
  }
}

.LogoSection-borders {
  border-bottom: 1px solid var(--warm-grey-9);
  height: 3rem;
  padding: 2rem 0;
  box-sizing: content-box;
}

/* TODO: define vertical breakpoint? */
@media screen and (min-height: 52em) {
  .LogoSection-borders {
    padding: 3rem 0;
  }
}

.LogoSection-logo {
  height: 100%;
}

.LogoSection-logoImage {
  opacity: 0.5;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  height: auto;
}

.Explanation-secondary {
  font-weight: 300;
}

.Explanation-tertiary {
  max-width: 30rem;
  color: color(var(--brand-text) alpha(80%));
}

/*
 * Cc-Clara
 */


.CcClara {
  color: var(--brand-text);
}

.CcClara.is-hidden {
  opacity: 0;
}

/*
 * What You Get
 */

.WhatYouGet {
  background-color: var(--brand-background-inverse);
  color: var(--brand-text-inverse);
}

/* TODO: wish this selector was better */
.WhatYouGet .container > .row:last-child {
  border-bottom: 1px solid var(--warm-grey-3);
}

.Point-secondary {
  inherit: %type-h3, %type-weight-reset;
  color: var(--brand-text-inverse);
  margin: 0;
}

.Point-tertiary {
  opacity: 0.6;
}

/*
 * testimonials
 */

.Testimonials {
  background-color: var(--brand-background-inverse);
  color: var(--brand-text-inverse);
}

.Testimonial {
  outline: 0;
  border: none;
}

.Testimonial:focus {
  outline: 0;
  border: none;
}

.Testimonial-picture {
  width: 100%;
  border-radius: .375rem;
}

.Testimonial-name {
  margin: 2rem 0 .5rem;
}

@media (--sm-viewport) {
  .Testimonial-name {
    margin: 0.5rem 0;
  }
}

.Testimonial-role {
  inherit: %type-h3, %type-weight-reset;
  margin: 0;
  opacity: 0.8;
}

.Testimonial-quote {
  inherit: %type-md;
  opacity: 0.5;
  margin: 1rem 0;
}

.slick-loading .slick-list {
  background: #fff url('/static/img/ajax-loader.gif') center center no-repeat;
}

.slick-dots li.slick-active button:before {
  /*color: var(--brand-text-inverse) !important;*/
  /*content: '●' !important;*/
  content: '' !important;
  background-color: white !important;

}

.slick-dots li button:before {
  /*color: var(--brand-text-inverse) !important;
  content: '○' !important;
  font-size: 1.5rem !important;
*/
  content: '' !important;
  border: 1px solid white !important;
  border-radius: 0.5rem !important;
  width: 0.5rem !important;
  height: 0.5rem !important;
}

.slick-dots {
  margin-top: 4em;
  margin-bottom: 2em;
  position: relative !important;
  bottom: auto !important;
}

.slick-slide {
  padding: 0 1rem;
}

@media (--md-viewport) {
  .slick-slide {
    padding: 0 2rem;
  }
}

@media (--xs-viewport) {
  .slick-dots li button:before {
    width: 1rem !important;
    height: 1rem !important;
    border-radius: 1rem !important;
  }
}

/*
 * complex needs
 */

.ComplexNeeds {
  color: var(--brand-text);
}

/* hack? */
.ComplexNeeds .container-fluid {
  overflow-x: hidden;
}

/* TODO(stephen): apply these in vogue */
.Button--secondary {
  background: none !important;
}

.Button--transparent {
  background-color: transparent;
}

.LandingForm-headingBuffer {
  margin-top: 2rem;
}
