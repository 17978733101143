/*
  also see: http://help.typekit.com/customer/portal/articles/6855-using-multiple-weights-and-styles;
 */

/* stylelint-disable rule-no-duplicate-properties */
@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebBlack.eot");
  src: url("./fonts/ColfaxWebBlack.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebBlackItalic.eot");
  src: url("./fonts/ColfaxWebBlackItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebBlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebBold.eot");
  src: url("./fonts/ColfaxWebBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebBoldItalic.eot");
  src: url("./fonts/ColfaxWebBoldItalic.eot?#iefix") format("embedded-opentype"),
    "./fonts/ColfaxWebBoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebMedium.eot");
  src: url("./fonts/ColfaxWebMedium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebMediumItalic.eot");
  src: url("./fonts/ColfaxWebMediumItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebMediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebRegular.eot");
  src: url("./fonts/ColfaxWebRegular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebRegularItalic.eot");
  src: url("./fonts/ColfaxWebRegularItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebRegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebLight.eot");
  src: url("./fonts/ColfaxWebLight.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebLightItalic.eot");
  src: url("./fonts/ColfaxWebLightItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebLightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebThin.eot");
  src: url("./fonts/ColfaxWebThin.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebThin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("./fonts/ColfaxWebThinItalic.eot");
  src: url("./fonts/ColfaxWebThinItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ColfaxWebThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

/* stylelint-enable */
