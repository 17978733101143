@import "vogue";

:root {
  -webkit-font-smoothing: antialiased;
}

@import "./utils.css";

@import "./components/nav.css";
@import "./components/hero.css";
@import "./components/footer.css";
@import "./components/ClaraLogo.css";
@import "./components/InfoCard.css";
@import "./components/Email.css";
@import "./components/SignupForm.css";

@import "./pages/landing.css";
@import "./pages/about.css";
@import "./pages/legal.css";
@import "./pages/404.css";
@import "./pages/pricing.css";
@import "./pages/welcome.css";

body {
  background-color: var(--brand-background);
  min-height: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
}

.Bodywrap {
  /*
    body is declared as flex. Bodywrap is used to have the body contents
    automatically push to the size to fill the window size if necessary
    and keep the footer sticky at the bottom
   */
  flex: 1 0 auto;
}

/* TODO(stephen): move to vogue */

.u-textH4--light {
  inherit: %type-weight-reset;
}

.Blockquote,
blockquote {
  inherit: %type-header-reset, %type-md;
  text-transform: uppercase;
  border-top: solid 1px color(var(--warm-grey-3) alpha(50%));
  border-bottom: solid 1px color(var(--warm-grey-3) alpha(50%));
  margin: 2rem 0;
  padding: 2rem 0;
}

.Button:focus {
  outline: 0;
}

.Button--black {
  background: var(--brand-background-inverse);
  color: var(--brand-text-inverse); 
}

.Button--black:focus {
  outline: 0;
}

.Button--skeleton {
  background-color: transparent;
  border-color: var(--brand-background);
  color: var(--brand-text-inverse);
}

.Button--skeleton:hover {
  background-color: var(--milky-white);
  color: var(--eclipse-black);
}


.SignupForm-inverse .TextField, .TextField--white {
  color: var(--milky-white) !important;
  border-color: color(var(--milky-white) alpha(80%)) !important;
}

.SignupForm-inverse .TextField-label, .TextField-label--white {
  color: color(var(--milky-white) alpha(80%)) !important; 
}

.TextField--white::placeholder {
  color: color(var(--milky-white) alpha(35%)) !important;
}

/* TODO(stephen): can we set these to be the global default? */

%type-anchor-default a,
%type-anchor-default a:visited {
  color: var(--brand-text);
  opacity: 0.8;
  font-weight: 500;
}

%type-anchor-default a:hover,
%type-anchor-default a:active {
  opacity: 1;
}

/*
  Do not add other mobile specific styles here.

  Add them to the base CSS and then add NON-MOBILE styles
  in appropriate media queries using --xs-viewport, --md-viewport, etc.

  Font sizes are easier to do this way because of the way Vogue
  defines them currently
*/

@media (--xs-viewport) {
  html {
    font-size: 11pt;
    line-height: 1.4rem;
  }
}

/* flexboxgrid extensions */

.container {
  width: 100%;
  max-width: 1300px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
}

@media (--sm-viewport) {
  .u-hidden-sm {
    display: none;
  }

  .u-block-sm {
    display: block;
  }

  .container {
    width: 95%;
    padding-left:4rem;
    padding-right:4rem;
  }
}

@media (--md-viewport) {
  .u-block-md {
    display: block;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
}
