.Nav {
  padding: 1.5rem 0 1rem;
  text-align: center;
  flex: none;
}

.Nav--landing {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
}

.Nav-link {
  inherit: %type-sm;

  color: var(--warm-grey-5);
  text-transform: uppercase;
  text-decoration: none;
  transition: 150ms ease-in-out;
}

.Nav-link:hover {
  color: var(--warm-grey-2);
}

.Nav-link.is-bold {
  font-weight: 500;
  color: var(--eclipse-black);
}

.Nav--white a {
  color: color(var(--milky-white) alpha(70%));
}

.Nav--white a:hover {
  color: var(--milky-white);
}
/* scrolled nav */

.Nav--scrolled {
  position: fixed;
  z-index: 1;
  top: 1.25rem;
  right: 1.25rem;
}

.Nav-link--sign-in {
  font-weight: 500;
  color: var(--eclipse-black);
}

@media (--sm-viewport) {
  .Nav-linkContainer--left {
    text-align: left;
  }

  .Nav-linkContainer--right {
    text-align: right;
  }
}
