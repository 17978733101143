/** @define utilities */

%type-sm {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

%type-bs {
  font-size: 12pt;
  line-height: 1.5rem;
}

%type-md {
  font-size: 1.5rem;
  line-height: 2rem;
}

%type-lg {
  font-size: 3rem;
  line-height: 3.5rem;
}

%type-xl {
  font-size: 4.5rem;
  line-height: 5rem;
}

/* semantic bases */

%type-header-reset {
  margin: 0;
  font-weight: normal;
}

%type-h1 {
  inherit: %type-header-reset, %type-xl;
  font-weight: 500;
}

%type-h2 {
  inherit: %type-header-reset, %type-lg;
}

%type-h3 {
  inherit: %type-header-reset, %type-md;
  text-transform: uppercase;
  font-weight: 500;
}

%type-h4 {
  inherit: %type-header-reset, %type-h3;
  text-transform: none;
}

%type-caption,
%type-navigation {
  inherit: %type-sm, %type-weight-500;
  text-transform: uppercase;
}

%type-body {
  color: color(var(--brand-text) alpha(80%));
}

%type-fancyNumber {
  inherit: %type-h1;
  font-weight: 400;
  color: color(var(--brand-text) alpha(20%));
}

%type-weight-500 {
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

.u-textH1 {
  inherit: %type-h1;
}

.u-textH2 {
  inherit: %type-h2;
}

.u-textH3 {
  inherit: %type-h3;
}

.u-textH3Light {
  inherit: %type-weight-reset;
}

/* deprecated utility. use .u-textH3Light */
/* postcss-bem-linter: ignore */
.u-textH3--light {
  inherit: .u-textH3Light;
}

.u-textH4 {
  inherit: %type-h4;
}

.u-textCaption {
  inherit: %type-caption;
}

.u-textCaptionLight {
  inherit: %type-weight-reset;
}

/* deprecated utility. use .u-textCaptionLight */
/* postcss-bem-linter: ignore */
.u-textCaption--light {
  inherit: .u-textCaptionLight;
}

.u-textFancyNumber {
  inherit: %type-fancyNumber;
}

.u-textWeight500 {
  inherit: %type-weight-500;
}

/* direct tag styling deprecated. use utility class directly */

/* postcss-bem-linter: ignore */
h1 {
  inherit: .u-textH1;
}

/* postcss-bem-linter: ignore */
h2 {
  inherit: .u-textH2;
}

/* postcss-bem-linter: ignore */
h3 {
  inherit: .u-textH3;
}

/* postcss-bem-linter: ignore */
h4 {
  inherit: .u-textH4;
}

/**
 * Override placeholders: need to be declared after other declarations to take effect in the right order
 */

%type-weight-reset {
  font-weight: 400;
}
