.NotFound {

  /* this is more or less .Hero, with a few changes */

  inherit: .Hero;
  padding-bottom: 10em;
  border-bottom: none;
}

.NotFound a {
  /*
    TODO(stephen): this is overriding %type-anchor-default.
    what is the expected behavior?
  */

  font-weight: 400;
}

.NotFound .NotFound-heading {
  inherit: %type-h3, %type-weight-reset;
	padding-top: 2rem;
  text-transform: none;
}
