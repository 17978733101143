:root {
  --timing-fn-pop: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --transition-fn-default: 200ms ease-out;
  --transition-fn-100ms: 100ms ease-out;
}

@keyframes animation-fn-pop {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
