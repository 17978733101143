.u-popIn {
  opacity: 0;
  position: relative;
  transform: scale(0.75);
  transition: opacity 400ms linear, transform 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.u-popIn.is-visible {
  opacity: 1;
  transform: scale(1);
}

.u-fadeOut {
  opacity: 0;
  position: relative;
  transform: translate3d(0, -2rem, 0);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.u-fadeOut.is-visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.u-fadeIn {
  opacity: 0;
  position: relative;
  transform: translate3d(0, 2rem, 0);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.u-fadeIn--full {
  transform: translate3d(0, 100%, 0);
}

.u-fadeIn--perspective {
  transform: perspective(20rem) rotateX(5deg) translate3d(0, 100%, 0);
  transition-duration: 400ms;
}

.u-fadeIn.is-visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.u-fadeIn--perspective.is-visible {
  transform: perspective(10rem) rotateX(0) translate3d(0, 0, 0);
}

.u-initiallyHidden {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.u-potentiallyHidden {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.u-initiallyHidden.is-visible {
  opacity: 1;
}

.u-initiallyPushed {
  margin-top: 2rem;
}

.u-isVisible {
  opacity: 1;
  display: inherit;
}

.u-isRemoved {
  display: none;
}

.u-flexMiddle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.u-2columns {
  column-count: 2;
  column-width: 18rem;
}

.u-noPadding {
  padding: 0;
}

.u-hidden-xs {
  display: none;
}

.u-flexEnd {
  align-self: flex-end;
}

/* Responsive Padding Classes */
/* TODO(stephen): convert to utility class. these are not a component */

.Spacing--large--top {
  padding-top: 5rem;
}

.Spacing--large--bottom {
  padding-bottom: 5rem;
}

.Spacing--medium--top {
  padding-top: 3rem;
}

.Spacing--medium--bottom {
  padding-bottom: 3rem;
}

.Spacing--small--top{
   padding-top: 1.5rem;
}

.Spacing--small--bottom{
   padding-bottom: 1.5rem;
}

@media (--md-viewport) {
  .Spacing--large--top{
    padding-top: 6rem;
  }

  .Spacing--large--bottom{
    padding-bottom: 6rem;
  }

  .Spacing--medium--top{
    padding-top: 4rem;
  }

  .Spacing--medium--bottom{
    padding-bottom: 4rem;
  }

  .Spacing--small--top{
     padding-top: 2rem;
  }

  .Spacing--small--bottom{
     padding-bottom: 2rem;
  }
}

@media (--lg-viewport) {
  .Spacing--large--top{
    padding-top: 10rem;
  }

  .Spacing--large--bottom{
    padding-bottom: 10rem;
  }

  .Spacing--medium--top{
    padding-top: 6rem;
  }

  .Spacing--medium--bottom{
    padding-bottom: 6rem;
  }

  .Spacing--small--top{
     padding-top: 3rem;
  }

  .Spacing--small--bottom{
     padding-bottom: 3rem;
  }
}

/* TODO(stephen): this is bad: we're overriding this style globally */
.u-textFancyNumber {
  color: var(--brand-text-inverse);
  opacity: 0.2;
}

.u-floatIn {
  top: 2rem;
  opacity: 0;
  transition: opacity 250ms ease-in-out, top 250ms ease-in-out;
  position: relative;
}

.u-floatedIn {
  opacity: 1;
  top: 0;
}
