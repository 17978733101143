.Footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: var(--eclipse-black);
  color: var(--milky-white);
  flex: none;
}

.Footer a {
  text-decoration: none;
}

.Footer-link {
  inherit: %type-sm;

  color: var(--warm-grey-5);
  text-transform: uppercase;
  transition: 150ms ease-in-out;
}

.Footer-link:hover,
.Footer-link:active {
  color: var(--milky-white);
}

.Footer-group {
  margin-bottom: 1rem;
}

@media (--sm-viewport) {
  .Footer-group {
    margin-bottom: 0rem;
  }
}
