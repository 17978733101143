#email-animation {
  /*height: 2150px;*/
  height: 1900px;
  position: relative;
  padding: 0 !important;
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.EmailAnimation-wrapper {
  width: 100vw;
  height: auto;

  margin: 0 auto;

  display: flex;
  align-items: center;
}

.EmailAnimation {

  position: relative;
  perspective: 1000px;

  width: 100%;
}

%AnimationBox {
  border-radius: 0.5em;
  width: 100%;
  background-color: #FFF;
  border: 1px solid var(--warm-grey-9);
  box-shadow: 0 10px 45px color(var(--eclipse-black) alpha(12%));
}

.Email {

  inherit: %AnimationBox;

  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 1000;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 1rem;
  max-width: 1200px;
}

.Email-headingType {
  color: var(--warm-grey-5);
  margin-right: .5rem;
}

.Email-headingText--empty {
  color: var(--warm-grey-3);
}

.Email-headingText {
  /* transform, opacity, background-color */
  transition: 300ms cubic-bezier(.5, -0.5, .5, 2);
  display: inline-block;
  padding: .4rem .35rem .25rem;
  border-radius: 3px;
}

.Email-headingText.is-hidden {
  opacity: 0;
  transform: scale(2) translateY(-1rem);
  transition: 50ms cubic-bezier(2, 0.5, -.5, .5);

  background-color: var(--brand-background);
  border-radius: .25rem;
  border: 1px solid var(--warm-grey-5);
  box-shadow: 0 2px 10px var(--warm-grey-1);
}

.Email-headingText.is-animating {
  animation: 1s Email-headingText--show;
  animation-fill-mode: forwards;
}

@keyframes Email-headingText--show {
  0% {
    background-color: var(--twilight-violet--lighter);
  }
  30% {
    background-color: color(var(--twilight-violet--lighter) alpha(80%));
  }
  100% {
    background-color: color(var(--twilight-violet--lighter) alpha(60%));
  }
}

.Email-headingItem {
  padding: 0.5rem 0;
}

.Email-headingItem:not(:last-child) {
  border-bottom: 1px solid var(--warm-grey-9);
}

.Email-heading {
  border-bottom: 1px solid var(--warm-grey-9);
  padding: 0rem 2rem;
}

.Email-body {
  padding: 1rem 2rem;
}

.Email-explanationText {
  color: var(--twilight-violet--light);
  font-size: 1.5rem;
  margin: 1.25rem 0;
}

.Email-highlight-color {
  color: var(--twilight-violet);
}

.Email-highlight {
  background-color: color(var(--twilight-violet--lighter) alpha(60%));
  padding: 0.3rem;
  border-radius: 3px;
}

.EmailAnimation-explanation {
  width: 100%;
  height: 60px;

  display: flex;

  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0px;

  opacity: 0;

  transition: opacity 100ms ease-in-out;
  margin: 0 !important;
}

.EmailAnimation-explanation.is-visible {
  opacity: 1;
}

.EmailAnimation-explanationText {
  font-weight: 400;
}

@import "./invite-box.css";
