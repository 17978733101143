/** @define SelectPlan */
.SelectPlan-hero {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.SelectPlan-plans {
  position: relative;
  padding-bottom: 2rem;
  padding-top: 4rem;
}

.SelectPlan-footnote {
  color: var(--warm-grey-5);
  padding-bottom: 4rem;
}

/** @define Plan */
.Plan {
  background-color: var(--warm-grey-9);
  padding: 1rem;
}

.Plan--popular {
  background-color: var(--warm-grey-3);
  color: var(--brand-text-inverse);
  padding: 1rem;
}

.Plan-column {
  position: relative;
}

.Plan-highlight {
  font-weight: 500;
}

.Plan-cost {
  padding: 2.5rem 0 1.5rem 0;
  min-height: 13rem;
  border-bottom: solid 4px var(--warm-grey-9);
  text-transform: uppercase;
}

.Plan-costSymbol {
  inherit: %type-h3, %type-weight-reset;

  position: relative;
  top: -2rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.Plan-costAmount {
  inherit: %type-fancyNumber;
  color: var(--brand-text);
  opacity: 1;
  margin: 1.6rem 0 0.5rem 0;

  display: inline-block;
}

.Plan-costUnit {
  opacity: 0.5;
}

.Plan-features {
  inherit: %type-anchor-default;
}

.Plan-featureList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* postcss-bem-linter: ignore */
.Plan-featureList > li {
  padding: 1.5em 1em;
  border-bottom: solid 1px var(--warm-grey-9);
}

/* postcss-bem-linter: ignore */
.Plan-featureList > li p {
  margin: 0;
}

.Plan-featureHeading {
  margin: 0;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Plan-byline {
  text-transform: none;
  opacity: 0.5;
  padding: 0;
  margin: 0;
}

.Plan-featureSublist {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.Plan-featureSublistTitle {
  opacity: 0.5;
  text-transform: uppercase;
}

/* postcss-bem-linter: ignore */
.Plan-featureSublist > li {
  padding-top: 0.7em;
}

/* postcss-bem-linter: ignore */
.Plan-featureSublist > li a {
  text-decoration: none;
  font-weight: normal;
  /* stylelint-disable declaration-no-important */
  color: var(--absolute-black) !important;
  opacity: 1 !important;
  /* stylelint-enable declaration-no-important */
}

/* postcss-bem-linter: ignore */
.Plan-featureSublist > li a:hover {
  text-decoration: underline;
}

.Plan-button {
  padding: 0 1rem;
}

/* postcss-bem-linter: ignore */
.Plan-button .Button {
  margin-top: 2rem;
}

@media (--xs-viewport) {
  .Plan-cost {
    min-height: 0;
  }

  .Plan-column {
    padding-top: 4.5rem;
  }
}

/** @define PricingFaq */
.PricingFaq-background {
  background-color: var(--eclipse-black);
}

.PricingFaq {
  color: var(--milky-white);
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.PricingFaq-header {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.PricingFaq-question {
  padding-bottom: 2rem;
}

/* postcss-bem-linter: ignore */
.PricingFaq-question h4 {
  font-size: 1.4rem;
}

.PricingFaq-bottom {
  padding-bottom: 0;
}

.PricingFaq-link {
  color: var(--milky-white);
}

/** @define PricingTestimonials */
.PricingTestimonials {
  padding-bottom: 4rem;
}

.PricingTestimonials-header {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.PricingTestimonials-header {
  border-top: solid 1px var(--warm-grey-9);
}

.PricingTestimonials-logos {
  height: 3rem;
  box-sizing: content-box;
  border-bottom: solid 1px #e5e4e1;
  padding-bottom: 4rem;
}

@media (--xs-viewport) {
  .PricingTestimonials-logos {
    padding-bottom: 6rem;
  }
}

@media (--sm-viewport-strict) {
  .PricingTestimonials-logos {
    padding-bottom: 6rem;
  }
}

.PricingTestimonials-logo {
  height: 100%;
  display: flex;
}

/* postcss-bem-linter: ignore */
.PricingTestimonials-logo img {
  opacity: 0.5;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  height: auto;
  align-self: center;
}

.PricingTestimonials-testimonialContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 4rem;
}

.PricingTestimonials-testimonial {
  align-self: center;
}

@media (--sm-viewport) {
  .PricingTestimonials-testimonial {
    padding-left: 3rem;
    width: 50%;
  }
}

@media (--xs-viewport) {
  .PricingTestimonials-testimonialContainer {
    flex-flow: wrap;
  }

  .PricingTestimonials-testimonial {
    margin-top: 3rem;
    width: 100%;
  }
}

.PricingTestimonials-quote {
  font-weight: normal;
}

.PricingTestimonials-attribution {
  margin-bottom: 0;
}

/** @define TeamPlan */
.TeamPlan {
  text-align: center;
  background-color: var(--brand-background-inverse);
  color: var(--brand-text-inverse);
}

.TeamPlan-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.TeamPlan-button {
  width: auto;
  margin-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.TeamPlan-price {
  margin-top: 3rem;
}

.TeamPlan-description {
  max-width: 50rem;
  margin: 0;
  padding-top: 2rem;
}
