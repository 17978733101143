/** @define InputPopover */

.InputPopover-textarea {
  background-color: transparent;
  color: var(--brand-text);

  width: 20rem;
  min-height: 15rem;
  outline: none;
  border-size: 0;
  margin: 0.5rem;
  padding: 0.5rem;
  resize: none;
  border: none;
}
