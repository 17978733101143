:root {
  --CultureSection-light: var(--warm-grey-5);
}

.Quote {
  text-indent: 0em;
}

@media (--sm-viewport) {
  .Quote {
    text-indent: -.5em;
  }
}
.JoinSection {
  background-color: var(--brand-background-inverse);
  color: var(--brand-text-inverse);
}

.JoinSection p.is-bold {
  font-weight: 500;
  margin: 2rem 0;
}

.JoinSection-content {
  min-height: 100vh;
}

.JoinSection-jobs {
  border-top: 1px solid color(var(--milky-white) alpha(25%));
  border-bottom: 1px solid color(var(--milky-white) alpha(25%));
  padding: 3.5rem 0;
  padding-bottom: 0;
}

.JoinSection-jobs-team {
  margin-bottom: 4rem;
}

.JoinSection-jobs-list {
  margin: 0rem;
  padding: 0rem;
  list-style-type: none;
}

.JoinSection-jobs-list li {
  margin-top: 1rem;
}

.JoinSection-job-title {
  inherit: %type-md;
  display: block;
  color: var(--milky-white);
  text-decoration: none;
}

.JoinSection-job-title:hover {
  text-decoration: underline;
}

.JoinSection-job-meta {
  inherit: %type-caption, %type-weight-reset;
  opacity: 0.75;
}

/* Join Us section photo styling thingies */

.JoinPhotoRow {
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
}

.JoinPhotoRow-inner {
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;

  width: 4608px;
}

.JoinPhotoRow-top {
  position: relative;
  left: 0vw;
  animation: photorow-move-top 120s infinite linear;
  align-items: flex-start;
}

.JoinPhotoRow-bottom {
  float: right;
  right: 0vw;
  position: relative;
  animation: photorow-move-bottom 120s infinite linear;
  align-items: flex-end;
}

.JoinPhoto {
  width: auto;
  height: 25rem;
  display: block;
}



.FoldingHeading {
  /* TODO(stephen): this isn't great... */
  margin-bottom: 2rem;
}

.LocationMap-image {
  display: block;
  width: 100%;
  filter: grayscale(100%);
}

.LocationMap-link {
  display: block;
  position: relative;
}

.LocationMap-link::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  bottom: 0;
  /*background: url("/static/img/location.svg") center no-repeat;*/

  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  background-color: var(--milky-white);

  transform: scale(1);
  animation: 1.5s LocationMap-linkAnimation infinite linear;

  text-align: center;
}

@keyframes LocationMap-linkAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

.LocationMap-link::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;

  margin: -4px auto 0;
  top: 50%;
  bottom: 0;

  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  border: .25rem solid var(--milky-white);
  background-color: var(--eclipse-black);
  box-shadow: 0px 0px 0px 1.25rem color(var(--eclipse-black) alpha(4%));

  text-align: center;
}

.ContactSection {
  inherit: %type-anchor-default;
}

.ContactSection a {
  text-decoration: none;
  opacity: 1 !important;
}

.ContactSection a:hover {
  text-decoration: underline;
}

.ContactSection-contactType {
  padding-bottom: 4rem;
}

.ContactSection-heading {
  inherit: %type-caption, %type-weight-reset;
  opacity: 0.75;
}

.Address {
  font-style: normal;
  background-color: var(--eclipse-black);
  color: var(--milky-white);
  padding: 1.5rem;
}

/* balance columns */
.u-2columns p:first-child {
  margin-top: 0;
}

.u-2columns p:last-child {
  margin-bottom: 0;
}


@keyframes photorow-move-top {
  0% { transform: translateX(0) }
  50% { transform: translateX(-2200px);}
  100% { transform: translateX(0) }
}

@keyframes photorow-move-bottom {
  0% { transform: translateX(0) }
  50% { transform: translateX(2200px);}
  100% { transform: translateX(0) }
}
