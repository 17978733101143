.InviteBox-wrapper {
  display: flex;
  justify-content: center;
}

.InviteBox {
  inherit: %AnimationBox;

  border-radius: 0.5em;
  max-width: 13rem;
  min-height: 20rem;

  transform: translateY(150vh); /* hack: over 100vh for laggy comps */
  transition: transform 400ms cubic-bezier(.5, -0.5, .5, 1.2);

  position: relative;
  overflow: hidden;
}

.InviteBox--email {
  transition-delay: 20ms;
}

.InviteBox.is-visible {
  transform: translateY(35rem);
}

.InviteBox:not(:last-child) {
  margin-right: 3rem;
}

.InviteBox--event::before {
  content: ' ';
  background-color: var(--sunset-red);

  display: block;
  height: 1rem;
  width: 100%;
}

.InviteBox--email .InviteBox-section {
  border-top: none;
}


.InviteBox-section {
  padding: 1rem 0rem;

  border-top: 1px solid var(--warm-grey-7);
}

.InviteBox-section {
  inherit: %type-sm;

  margin: 0rem 1rem;
}

.InviteBox--event .Invite-section {
  border-top: 1px solid var(--warm-grey-7);
}

.InviteBox-heading {
  padding: 2rem 1rem;

  display: flex;

  justify-content: flex-start;
  align-items: flex-end;
}

.InviteBox-time {
  inherit: %type-bs;
}

.InviteBox-dateLabel {
  margin-left: .5rem;
}

.InviteBox-date {
  font-size: 5rem;
  line-height: .8em;
}

.InviteBox-month,
.InviteBox-day {
  text-transform: uppercase;
}

.InviteBox-stamp {
  transform: scale(1) rotate(0deg);
  transition: transform 200ms cubic-bezier(.5, -0.5, .5, 2), opacity 200ms cubic-bezier(.5, -0.5, .5, 2);
  transition-delay: 400ms;
  opacity: 0;

  position: absolute;
  bottom: 0rem;
  right: -2rem;
}

.is-visible .InviteBox-stamp {
  transform: scale(.75);
  opacity: 1;
}

@media (--xs-viewport) {
  .InviteBox--event.is-visible {
    transform: rotateZ(-4deg) translateY(38rem) translateX(-1rem);
  }

  .InviteBox--email.is-visible {
    transform: rotateZ(4deg) translateY(38rem) translateX(1rem);
  }
}
