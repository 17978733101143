/**
 * @define utilities
 * Offset: breakpoint 1 (small)
 *
 * Specify the proportional offset after an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 4, 5, 6, 8, 10, 12 section
 */

@media (--sm-viewport) {

  .u-sm-afterNone {
    margin-right: 0 !important;
  }

  .u-sm-after1of12 {
    margin-right: calc(100% * 1 / 12) !important;
  }

  .u-sm-after1of10 {
    margin-right: 10% !important;
  }

  .u-sm-after1of8 {
    margin-right: 12.5% !important;
  }

  .u-sm-after1of6,
  .u-sm-after2of12 {
    margin-right: calc(100% * 1 / 6) !important;
  }

  .u-sm-after1of5,
  .u-sm-after2of10 {
    margin-right: 20% !important;
  }

  .u-sm-after1of4,
  .u-sm-after2of8,
  .u-sm-after3of12 {
    margin-right: 25% !important;
  }

  .u-sm-after3of10 {
    margin-right: 30% !important;
  }

  .u-sm-after1of3,
  .u-sm-after2of6,
  .u-sm-after4of12 {
    margin-right: calc(100% * 1 / 3) !important;
  }

  .u-sm-after3of8 {
    margin-right: 37.5% !important;
  }

  .u-sm-after2of5,
  .u-sm-after4of10 {
    margin-right: 40% !important;
  }

  .u-sm-after5of12 {
    margin-right: calc(100% * 5 / 12) !important;
  }

  .u-sm-after1of2,
  .u-sm-after2of4,
  .u-sm-after3of6,
  .u-sm-after4of8,
  .u-sm-after5of10,
  .u-sm-after6of12 {
    margin-right: 50% !important;
  }

  .u-sm-after7of12 {
    margin-right: calc(100% * 7 / 12) !important;
  }

  .u-sm-after3of5,
  .u-sm-after6of10 {
    margin-right: 60% !important;
  }

  .u-sm-after5of8 {
    margin-right: 62.5% !important;
  }

  .u-sm-after2of3,
  .u-sm-after4of6,
  .u-sm-after8of12 {
    margin-right: calc(100% * 2 / 3) !important;
  }

  .u-sm-after7of10 {
    margin-right: 70% !important;
  }

  .u-sm-after3of4,
  .u-sm-after6of8,
  .u-sm-after9of12 {
    margin-right: 75% !important;
  }

  .u-sm-after4of5,
  .u-sm-after8of10 {
    margin-right: 80% !important;
  }

  .u-sm-after5of6,
  .u-sm-after10of12 {
    margin-right: calc(100% * 5 / 6) !important;
  }

  .u-sm-after7of8 {
    margin-right: 87.5% !important;
  }

  .u-sm-after9of10 {
    margin-right: 90% !important;
  }

  .u-sm-after11of12 {
    margin-right: calc(100% * 11 / 12) !important;
  }

}
