.Hero {
  inherit: %type-anchor-default;
  padding-top: 20vh;
  padding-bottom: 10vh;
}

.Hero:not(.Hero--noRule) {
  border-bottom: solid 1px color(var(--warm-grey-9) alpha(80%));
}

.Hero--small {
  padding-top: 10vh;
  padding-bottom: 5vh;
}

.Hero-aside {
  inherit: %type-sm;
  position: relative;
  bottom: -.5rem;
}

.Hero-asideHeading {
  text-transform: uppercase;
  display: block;
}
