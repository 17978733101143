/** @define ControlWithLabel */
.ControlWithLabel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.ControlWithLabel--reverseOrder {
  flex-direction: row-reverse;
}

.ControlWithLabel-content {
  flex: 1;
}

.ControlWithLabel:not(.ControlWithLabel--reverseOrder) .ControlWithLabel-content {
  margin-left: 1rem;
}

.ControlWithLabel--reverseOrder .ControlWithLabel-content {
  margin-right: 1rem;
}
