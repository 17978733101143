
:root {

  /* grey colors */

  --milky-white: #fffefc;
  --eclipse-black: #1a1814;
  --washed-clay: #f5f3ee;

  --absolute-black: #000000;

  --warm-grey-10: #f5f4f0;
  --warm-grey-9: #e5e4e1;
  --warm-grey-7: #b2b1ad;
  --warm-grey-5: #807e7a;
  --warm-grey-3: #4c4b48;
  --warm-grey-2: #33322e;
  --warm-grey-1: #24231f;

  /* colors */

  --sunset-red: #e65134;
  --sunset-red--lighter: color(var(--sunset-red) blend(var(--milky-white) 50%));
  --sunset-red--light: color(var(--sunset-red) blend(var(--milky-white) 20%));
  --sunset-red--dark: color(var(--sunset-red) blend(var(--absolute-black) 20%));
  --sunset-red--darker: color(var(--sunset-red) blend(var(--absolute-black) 40%));

  --sunny-orange: #fb7e32;
  --sunny-orange--lighter: color(var(--sunny-orange) blend(var(--milky-white) 50%));
  --sunny-orange--light: color(var(--sunny-orange) blend(var(--milky-white) 20%));
  --sunny-orange--dark: color(var(--sunny-orange) blend(var(--absolute-black) 20%));
  --sunny-orange--darker: color(var(--sunny-orange) blend(var(--absolute-black) 40%));

  --california-yellow: #ffb424;
  --california-yellow--lighter: color(var(--california-yellow) blend(var(--milky-white) 50%));
  --california-yellow--light: color(var(--california-yellow) blend(var(--milky-white) 20%));
  --california-yellow--dark: color(var(--california-yellow) blend(var(--absolute-black) 20%));
  --california-yellow--darker: color(var(--california-yellow) blend(var(--absolute-black) 40%));

  --aurora-green: #14a67b;
  --aurora-green--lighter: color(var(--aurora-green) blend(var(--milky-white) 50%));
  --aurora-green--light: color(var(--aurora-green) blend(var(--milky-white) 20%));
  --aurora-green--dark: color(var(--aurora-green) blend(var(--absolute-black) 20%));
  --aurora-green--darker: color(var(--aurora-green) blend(var(--absolute-black) 40%));

  --sky-blue: #62c5e5;
  --sky-blue--lighter: color(var(--sky-blue) blend(var(--milky-white) 50%));
  --sky-blue--light: color(var(--sky-blue) blend(var(--milky-white) 20%));
  --sky-blue--dark: color(var(--sky-blue) blend(var(--absolute-black) 20%));
  --sky-blue--darker: color(var(--sky-blue) blend(var(--absolute-black) 40%));

  --royal-blue: #5780c2;
  --royal-blue--lighter: color(var(--royal-blue) blend(var(--milky-white) 50%));
  --royal-blue--light: color(var(--royal-blue) blend(var(--milky-white) 20%));
  --royal-blue--dark: color(var(--royal-blue) blend(var(--absolute-black) 20%));
  --royal-blue--darker: color(var(--royal-blue) blend(var(--absolute-black) 40%));

  --twilight-violet: #795f99;
  --twilight-violet--lighter: color(var(--twilight-violet) blend(var(--milky-white) 50%));
  --twilight-violet--light: color(var(--twilight-violet) blend(var(--milky-white) 20%));
  --twilight-violet--dark: color(var(--twilight-violet) blend(var(--absolute-black) 20%));
  --twilight-violet--darker: color(var(--twilight-violet) blend(var(--absolute-black) 40%));

  --dusky-pink: #fa7187;
  --dusky-pink--lighter: color(var(--dusky-pink) blend(var(--milky-white) 50%));
  --dusky-pink--light: color(var(--dusky-pink) blend(var(--milky-white) 20%));
  --dusky-pink--dark: color(var(--dusky-pink) blend(var(--absolute-black) 20%));
  --dusky-pink--darker: color(var(--dusky-pink) blend(var(--absolute-black) 40%));

  /* semantic colors */

  --brand-text: var(--eclipse-black);
  --brand-text-inverse: var(--milky-white);

  --brand-background: var(--milky-white);
  --brand-background-inverse: var(--eclipse-black);

  --brand-confirm: var(--aurora-green);
  --brand-reject: var(--sunset-red);

  /* gradients */

  --sunrise-stops: var(--twilight-violet), var(--sunset-red);
  --sunset-stops: var(--sunset-red), var(--california-yellow);
  --noon-stops: var(--sky-blue), var(--washed-clay);
  --day-at-beach-stops: var(--aurora-green), var(--sky-blue);
  --dusk-stops: var(--sunset-red), var(--dusky-pink);
  --twilight-stops: var(--dusky-pink), var(--twilight-violet);
  --evening-stops: var(--twilight-violet), var(--sky-blue);
  --auroras-stops: var(--twilight-violet), var(--aurora-green);
}
