.SignupForm {
  text-align: center;
  background-color: var(--warm-grey-10);
}

.SignupForm--inline {
  margin-top: 8em;
  align-items: baseline;
}

.SignupForm--inline,
.SignupForm-primary {
  margin: 0;
}

.SignupForm--inline,
.SignupForm-secondary {
  margin: 0;
  font-weight: 300;
}

.SignupForm--regular,
.SignupForm-left {
  font-weight: 400;
}

.SignupForm--regular,
.SignupForm-form {
  margin-top: 1em;
}

.SignupForm-buttonImage {
  max-width: 2.5rem;
  display: block;
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 200ms ease-in-out, transform 250ms ease-in-out;
}

.SignupForm-buttonImage:hover {
  opacity: 0.9;
  transform: scale(1.1)
}

input.TextField {
  z-index: 10;
  position: relative;
}

.TextField-label {
  z-index: 0 !important;
  text-align: left;
}

.SignupForm--inline .SignupForm-button {
  height: 3.5rem;
  width: 100%;
}

.SignupForm-left,
.SignupForm--inline .col-xs-12 {
  padding: 0;
}

.SignupForm--stacked .Button--secondary {
  padding: 10px;
}

.SignupForm--inline .Button--secondary {
  padding: 1em;
}

.SignupForm--stacked {
  margin-top: 1em;
}

.SignupForm--stacked .SignupForm-email {
  margin-bottom: 1.5rem;
}

@media (--xs-viewport) {
  .SignupForm-email {
    margin-bottom: 1.5rem;
  }
}

@media (--sm-viewport) {
  .SignupForm-textfieldContainer {
    padding-right: 1rem !important;
  }
}

