@import "normalize.css";
@import "flexboxgrid/src/css/flexboxgrid.css";
@import "suitcss-utils";

/* override flexboxgrid variables */
:root {
  --gutter-width: 1rem;
  --outer-margin: 2rem;

  /**
   * these *must* match the custom-media boundaries below for responsive
   * layouts to be computed properly
   */
  --sm-min: 700px;
  --md-min: 1000px;
  --lg-min: 1400px;
}

@custom-media --xs-viewport only screen and (max-width: 699px);
@custom-media --sm-viewport only screen and (min-width: 700px);
@custom-media --md-viewport only screen and (min-width: 1000px);
@custom-media --lg-viewport only screen and (min-width: 1400px);
@custom-media --sm-viewport-strict only screen and (min-width: 700px) and (max-width: 999px);
@custom-media --md-viewport-strict only screen and (min-width: 1000px) and (max-width: 1399px);

* {
  box-sizing: border-box;
}

html {
  inherit: %type-bs;
  font-family: "Colfax", sans-serif;
}

html,
body {
  height: 100%;
}

@import "./utils.css";
@import "./animations.css";
@import "./z-indexes.css";
@import "./fonts.css";
@import "./colors.css";
@import "./shadows.css";
@import "./typography.css";
@import "./components/button.css";
@import "./components/loader.css";
@import "./components/modal.css";
@import "./components/input-popover.css";
@import "./components/popover.css";
@import "./components/popover-menu.css";
@import "./components/popover-action-bar.css";
@import "./components/checkbox.css";
@import "./components/radio-button.css";
@import "./components/control-with-label.css";
@import "./components/tab-panel.css";
@import "./components/text-field.css";
